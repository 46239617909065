.offer-img{
    height: 250px !important;
    margin-top: 50px;
}

.offer-content{
    margin-bottom: 80px !important;
}

.cheight{
    height: 180px;
}

.ttop{
    top: 85% !important;
}