

.bs-height{
    height: 70vh;
    color: white;

}

.carousel-radius{
    border-radius: 25px;
}

.carousel-img{
    background-color: red !important;
}